import WeeklyRevenue from "./components/WeeklyRevenue";
import PieChartCard from "./components/PieChartCard";
import Widget from "components/widget/Widget";
import DailyTraffic from "./components/DailyTraffic";
import { MdDocumentScanner, MdRateReview } from "react-icons/md";
import { FaUserTie, FaUser } from "react-icons/fa";
import {
  useQueryCountProfessionals,
  useQueryCountUsers,
  useQueryCountOrders,
  useQueryOrderStatCity,
  useQueryOrderStatService,
} from "graphQl/hooks";
const Dashboard = () => {
  const { countUsers } = useQueryCountUsers(null);
  const { countProfessionals } = useQueryCountProfessionals(null);
  const { countOrders } = useQueryCountOrders(null, null, null, null);
  const { ordersStatPerCity } = useQueryOrderStatCity();
  const { ordersStatPerService } = useQueryOrderStatService();
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          icon={<MdDocumentScanner className="h-6 w-6" color="#094cbb" />}
          title={"Nb missions"}
          subtitle={countOrders}
          link="/admin/ordres"
        />
        <Widget
          icon={<FaUserTie className="h-6 w-6" color="#094cbb" />}
          title={"Nb happyeurs"}
          subtitle={countProfessionals}
          link="/admin/happyeurs"
        />
        <Widget
          icon={<FaUser className="h-6 w-6" color="#094cbb" />}
          title={"Nb ainés"}
          subtitle={countUsers}
          link="/admin/aines"
        />
        <Widget
          icon={<MdRateReview className="h-6 w-6" color="#094cbb" />}
          title={"Nb avis"}
          subtitle={1}
        />
      </div>

      {/* Charts */}
      <div className="mt-5 grid grid-cols-1 ">
        {countOrders && ordersStatPerCity && (
          <DailyTraffic
            countOrders={countOrders}
            ordersStatPerCity={ordersStatPerCity}
          />
        )}
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {ordersStatPerService && (
          <PieChartCard ordersStatPerService={ordersStatPerService} />
        )}
        <WeeklyRevenue />
      </div>
    </div>
  );
};

export default Dashboard;

import Card from "components/card";
import { Link } from "react-router-dom";

const Widget = ({ icon, title, subtitle, link }) => {
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      {link ? (
        <Link className="flex cursor-pointer items-center gap-2" to={link}>
          <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
            <div className="rounded-full border-2 border-[#094cbb] p-3 dark:bg-navy-800">
              <span className="flex items-center text-brand-500 dark:text-white">
                {icon}
              </span>
            </div>
          </div>

          <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <p className="font-dm text-sm font-medium text-[#4263c9]">
              {title}
            </p>
            <h4 className="text-xl font-bold text-primary-800 dark:text-white">
              {subtitle}
            </h4>
          </div>
        </Link>
      ) : (
        <>
          <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
            <div className="rounded-full border-2 border-[#094cbb] p-3 dark:bg-navy-800">
              <span className="flex items-center text-brand-500 dark:text-white">
                {icon}
              </span>
            </div>
          </div>

          <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <p className="font-dm text-sm font-medium text-[#4263c9]">
              {title}
            </p>
            <h4 className="text-xl font-bold text-primary-800 dark:text-white">
              {subtitle}
            </h4>
          </div>
        </>
      )}
    </Card>
  );
};

export default Widget;

import Banner from "./components/Banner";
import General from "./components/General";
import Orders from "../orders";

import { useMutationUser, useQueryUser } from "graphQl/hooks";
import { useParams } from "react-router-dom";

const UserProfile = () => {
  const params = useParams();
  const { user, loading, refetch, error } = useQueryUser(params?.id);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner user={user} />
        </div>

        <div className="col-span-8 lg:col-span-8 lg:mb-0 3xl:col-span-8">
          <General user={user} />
        </div>
      </div>

      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-12 lg:col-span-12 lg:mb-0 3xl:col-span-12">
          <Orders user={user?.id} />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

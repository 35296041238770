import { useState, useEffect } from "react";
import TopCreatorTable from "./components/TableTopCreators";
import {
  useMutationUser,
  useQueryUsers,
  useQueryCountUsers,
} from "graphQl/hooks";
import Pagination from "components/pagination/Pagination";
import { toast } from "react-toastify";
import { useAdminContext } from "layouts/admin";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export const tableColumnsTopCreators = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "ADRESSE",
    accessor: "address",
  },
  {
    Header: "STATUT",
    accessor: "status",
  },
];

const User = () => {
  const { search } = useAdminContext();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const { users, loading, refetch } = useQueryUsers(skip, limit, search);
  const { countUsers } = useQueryCountUsers(search);
  const { changeUserStatusHandler, deleteUserHandler } = useMutationUser();

  const handleStatus = (row, value) => {
    changeUserStatusHandler({
      id: row?.id,
      status: value ? "ACTIVE" : "INACTIVE",
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success(
          "Statut du ainé " + row?.profile?.lastName + " modifié avec succès"
        );
        refetch();
      }
    });
  };
  const handelRemove = (row) => {
    confirmAlert({
      title: "Suppression",
      message: "Voulez-vous supprimé " + row?.profile?.lastName,
      buttons: [
        {
          label: "OUI",
          onClick: () =>
            deleteUserHandler({
              id: row?.id,
            }).then((httpResponse) => {
              if (!httpResponse?.errors) {
                toast.success(
                  "L'ainé " +
                    row?.profile?.lastName +
                    " a été supprimé avec succès"
                );
                refetch();
              }
            }),
        },
        {
          label: "NON",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        {users && !loading && (
          <TopCreatorTable
            extra="mb-5"
            tableData={users}
            columnsData={tableColumnsTopCreators}
            handleStatus={handleStatus}
            handelRemove={handelRemove}
          />
        )}
      </div>
      {countUsers > limit && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countUsers}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default User;

import { useState, useEffect } from "react";
import LogInForm from "./components/LogInForm";
import CheckResetPasswordCode from "./components/CheckResetPasswordCode";
import SendResetPasswordCode from "./components/SendResetPasswordCode";
import ResetPassword from "./components/ResetPassword";
import { useNavigate } from "react-router-dom";
const Screens = {
  LOGIN: "LOGIN",
  SEND_CODE: "SEND_CODE",
  CHECK_CODE: "CHECK_CODE",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export default function SignIn() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    screen: Screens.LOGIN,
    user: null,
    token: null,
  });
  const setForgotPasword = () =>
    setState({ ...state, screen: Screens.SEND_CODE });
  const setLogin = () =>
    setState({ ...state, screen: Screens.LOGIN, user: null, token: null });
  const setUser = (user) =>
    setState({ ...state, user, screen: Screens.CHECK_CODE });
  const setToken = (token) =>
    setState({ ...state, token, screen: Screens.RESET_PASSWORD });
  const onSuccess = (token) => {
    localStorage.setItem("token-pappy", "Bearer " + token);
    navigate("/admin");
  };
  const renderScreen = () => {
    switch (state.screen) {
      case Screens.LOGIN:
        return (
          <LogInForm
            onSuccess={onSuccess}
            setForgotPasword={setForgotPasword}
          />
        );
      case Screens.SEND_CODE:
        return <SendResetPasswordCode setUser={setUser} setLogin={setLogin} />;
      case Screens.CHECK_CODE:
        return (
          <CheckResetPasswordCode
            setLogin={setLogin}
            setToken={setToken}
            user={state.user}
          />
        );
      case Screens.RESET_PASSWORD:
        return (
          <ResetPassword
            user={state.user}
            token={state.token}
            setLogin={setLogin}
          />
        );
    }
  };
  return renderScreen();
}

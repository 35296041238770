import { useState } from "react";
import TopCreatorTable from "./components/TableTopCreators";
import {
  useMutationProfessional,
  useQueryProfessionals,
  useQueryCountProfessionals,
} from "graphQl/hooks";
import Pagination from "components/pagination/Pagination";
import { toast } from "react-toastify";
import { useAdminContext } from "layouts/admin";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
export const tableColumnsTopCreators = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "ADRESSE",
    accessor: "address",
  },
  {
    Header: "STATUT",
    accessor: "status",
  },
];

const Professional = () => {
  const { search } = useAdminContext();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const { professionals, loading, refetch } = useQueryProfessionals(
    skip,
    limit,
    search
  );
  const { countProfessionals } = useQueryCountProfessionals(search);
  const { changeProfessionalStatusHandler, deleteProfessionalHandler } =
    useMutationProfessional();

  const handleStatus = (row, value) => {
    changeProfessionalStatusHandler({
      id: row?.id,
      status: value ? "ACTIVE" : "INACTIVE",
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success(
          "Statut du happyeur " +
            row?.profile?.lastName +
            " modifié avec succès"
        );
        refetch();
      }
    });
  };
  const handelRemove = (row) => {
    confirmAlert({
      title: "Suppression",
      message: "Voulez-vous supprimé " + row?.profile?.lastName,
      buttons: [
        {
          label: "OUI",
          onClick: () =>
            deleteProfessionalHandler({
              id: row?.id,
            }).then((httpResponse) => {
              if (!httpResponse?.errors) {
                toast.success(
                  "Le happyeur " +
                    row?.profile?.lastName +
                    " a été supprimé avec succès"
                );
                refetch();
              }
            }),
        },
        {
          label: "NON",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        {professionals && !loading && (
          <TopCreatorTable
            extra="mb-5"
            tableData={professionals}
            columnsData={tableColumnsTopCreators}
            handleStatus={handleStatus}
            handelRemove={handelRemove}
          />
        )}
      </div>
      {countProfessionals > limit && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countProfessionals}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default Professional;

import Card from "components/card";
import BarChart from "components/charts/BarChart";
import {
  barChartDataWeeklyRevenue,
  barChartOptionsWeeklyRevenue,
} from "variables/charts";

const WeeklyRevenue = () => {
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-black-800 text-lg font-bold dark:text-white">
            Avis
          </h4>
        </div>

        <div className="mb-6 flex items-center justify-center">
          <select className="dark:!bg-black-800 mb-3 mr-2 flex items-center justify-center rounded-[4px] py-1 px-2 text-sm font-bold text-gray-600 hover:cursor-pointer dark:text-white">
            <option value="Mensuel">Mensuel</option>
            <option value="Annuel">Annuel</option>
            <option value="weekly">Hebdomadaire</option>
          </select>
        </div>
      </div>

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          <BarChart
            chartData={barChartDataWeeklyRevenue}
            chartOptions={barChartOptionsWeeklyRevenue}
          />
        </div>
      </div>
    </Card>
  );
};

export default WeeklyRevenue;

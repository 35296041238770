import { useMutation, useQuery } from "@apollo/client";
import {
  CATEGORIES,
  COUNT_CATEGORIES,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  REMOVE_CATEGORY,
} from "graphQl/tags";

export const useQueryCategories = (skip, limit, value = null) => {
  const { data, loading, refetch } = useQuery(CATEGORIES, {
    variables: {
      skip,
      limit,
      value,
    },
    fetchPolicy: "no-cache",
  });
  return {
    categories: data?.categories,
    loading,
    refetch,
  };
};
export const useQueryCountCategories = (value) => {
  const { data, loading, refetch } = useQuery(COUNT_CATEGORIES, {
    variables: {
      value,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countCategories: data?.countCategories,
    loading,
    refetch,
  };
};
export const useMutationCategory = () => {
  const [createCategory] = useMutation(CREATE_CATEGORY);
  const [removeCategory] = useMutation(REMOVE_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  function addCategoryHandler({ category }) {
    return createCategory({
      variables: {
        category,
      },
    });
  }

  function deleteCategoryHandler({ id }) {
    return removeCategory({
      variables: {
        id,
      },
    });
  }
  function updateCategoryHandler({ category, id }) {
    return updateCategory({
      variables: {
        id,
        category,
      },
    });
  }
  return {
    addCategoryHandler,
    updateCategoryHandler,
    deleteCategoryHandler,
  };
};

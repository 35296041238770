import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkToken } from "api/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function PrivateRoute({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token-pappy");
    if (token) {
      checkToken(token)
        .then((response) => {})
        .catch((err) => {
          localStorage.removeItem("token-puppy");
          navigate("/auth/sign-in");
        });
    } else {
      localStorage.removeItem("token-puppy");
      navigate("/auth/sign-in");
    }
  }, []);

  return (
    <React.Fragment>
      {children}
      <ToastContainer />
    </React.Fragment>
  );
}
export default PrivateRoute;

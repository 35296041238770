import Card from "components/card";
import React from "react";

const General = () => {
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="text-black-800 px-2 text-xl font-bold dark:text-white">
          General Information
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          As we live, our hearts turn colder. Cause pain is what we go through
          as we become older. We get insulted by others, lose trust for those
          others. We get back stabbed by friends. It becomes harder for us to
          give others a hand. We get our heart broken by people we love, even
          that we give them all...
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="dark:!bg-black-800 flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:shadow-none">
          <p className="text-sm text-gray-600">Education</p>
          <p className="text-black-800 text-base font-medium dark:text-white">
            Stanford University
          </p>
        </div>

        <div className="dark:!bg-black-800 flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:shadow-none">
          <p className="text-sm text-gray-600">Languages</p>
          <p className="text-black-800 text-base font-medium dark:text-white">
            English, Spanish, Italian
          </p>
        </div>

        <div className="dark:!bg-black-800 flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:shadow-none">
          <p className="text-sm text-gray-600">Department</p>
          <p className="text-black-800 text-base font-medium dark:text-white">
            Product Design
          </p>
        </div>

        <div className="dark:!bg-black-800 flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:shadow-none">
          <p className="text-sm text-gray-600">Work History</p>
          <p className="text-black-800 text-base font-medium dark:text-white">
            English, Spanish, Italian
          </p>
        </div>

        <div className="dark:!bg-black-800 flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:shadow-none">
          <p className="text-sm text-gray-600">Organization</p>
          <p className="text-black-800 text-base font-medium dark:text-white">
            Simmmple Web LLC
          </p>
        </div>

        <div className="dark:!bg-black-800 flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:shadow-none">
          <p className="text-sm text-gray-600">Birthday</p>
          <p className="text-black-800 text-base font-medium dark:text-white">
            20 July 1986
          </p>
        </div>
      </div>
    </Card>
  );
};

export default General;

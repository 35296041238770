import { gql } from "@apollo/client";

export const SERVICES = gql`
  query services($limit: Int!, $skip: Int!, $value: String, $category: String) {
    services(limit: $limit, skip: $skip, value: $value, category: $category) {
      id
      name
      status
      category {
        id
        name
      }
      createdAt
    }
  }
`;
export const COUNT_SERVICES = gql`
  query countServices($value: String, $category: String) {
    countServices(value: $value, category: $category)
  }
`;
export const CREATE_SERVICE = gql`
  mutation createService($service: ServiceInput!) {
    createService(service: $service) {
      id
      name
      status
      createdAt
    }
  }
`;
export const UPDATE_SERVICE = gql`
  mutation updateService($id: ID!, $service: ServiceInput!) {
    updateService(service: $service, id: $id) {
      id
      name
      status
      createdAt
    }
  }
`;
export const REMOVE_SERVICE = gql`
  mutation removeService($id: ID!) {
    removeService(id: $id) {
      success
      message
    }
  }
`;

import React from "react";
import avatar from "assets/img/avatars/avatar.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import moment from "moment";
import { MdMale, MdFemale } from "react-icons/md";
import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");

const Banner = ({ user }) => {
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-14 flex h-[120px] w-[120px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-800">
          <img
            className="h-full w-full rounded-full"
            src={user?.profile?.photoUrl ? user?.profile?.photoUrl : avatar}
            alt=""
          />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-black-800 flex text-xl font-semibold dark:text-white">
          {user?.profile?.sexe === "MALE" ? (
            <MdMale color="blue" className="h-6 w-6 cursor-pointer" />
          ) : (
            <MdFemale color="red" className="h-6 w-6 cursor-pointer" />
          )}
          {user?.profile?.lastName + " " + user?.profile?.firstName}
        </h4>
        <p className="text-base font-normal text-[#4263c9]">{user?.email}</p>
      </div>

      {/* Post followers */}
      <div className="mt-6 mb-3 flex gap-2 md:!gap-10">
        <div className="flex flex-col items-start justify-start">
          <p className="text-md text-black-800  font-semibold">Membre depuis</p>
          <p className="text-sm font-normal text-[#4263c9] dark:text-white">
            {moment(user?.createdAt).fromNow()}
          </p>
        </div>
        <div className="flex flex-col items-start justify-start">
          <p className="text-md text-black-800  font-semibold">Statut</p>
          <p
            className={`text-sm font-normal ${
              user?.status === "ACTIVE" ? "text-green-600" : "text-gray-600"
            }  dark:text-white`}
          >
            {user?.status === "ACTIVE" ? "Actif" : "Inactif"}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;

import { useState, useEffect, Fragment } from "react";
import ComplexTable from "./components/ComplexTable";
import {
  useQueryOrderRequests,
  useQueryCountOrderRequests,
} from "graphQl/hooks";
import Modal from "./components/Modal";
import Pagination from "components/pagination/Pagination";
import { useAdminContext } from "layouts/admin";

const columnsDataComplex = [
  {
    Header: "NUMÉRO",
    accessor: "code",
  },
  {
    Header: "CRÉÉE PAR",
    accessor: "user",
  },
  {
    Header: "POUR LE",
    accessor: "orderDate",
  },
  {
    Header: "Nb PROPOSITIONS",
    accessor: "confirmedProfessionals",
  },
];

const OrderRequests = ({ user }) => {
  const { search } = useAdminContext();

  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(false);
  const [status, setStatus] = useState("PENDING");

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  const columnsDataComplex = [
    {
      Header: "NUMÉRO",
      accessor: "code",
    },
    {
      Header: "CRÉÉE PAR",
      accessor: "user",
    },
    {
      Header: "POUR LE",
      accessor: "orderDate",
    },
    {
      Header: status === "PENDING" ? "Nb PROPOSITIONS" : "RAISON",
      accessor: status === "PENDING" ? "confirmedProfessionals" : "status",
    },
  ];
  const clear = () => {
    setOrder(null);
  };
  const handleOpen = () => {
    if (open) clear();
    setOpen(!open);
    if (order) clear();
  };
  const setOrderHandle = ({ original }) => {
    setOrder(original);
    handleOpen();
  };
  const { orderRequests, loadingOrders, refetchOrders } = useQueryOrderRequests(
    skip,
    limit,
    status,
    search,
    null,
    user
  );
  const { countOrderRequests, loading, refetch } = useQueryCountOrderRequests(
    status,
    search,
    null,
    user
  );
  useEffect(() => {}, [skip, limit, countOrderRequests, orderRequests, status]);
  return (
    <div>
      {orderRequests && !loadingOrders && (
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <div className="flex flex-row">
            <button
              className={`linear mr-2 inline-flex w-full w-[30%] justify-center rounded-md  py-[12px] text-base font-medium text-white transition duration-200 dark:text-white ${
                status === "PENDING" ? "bg-green-500" : "bg-gray-500"
              }`}
              type="button"
              onClick={() => setStatus("PENDING")}
            >
              En attente
            </button>
            <button
              className={`linear mr-2 inline-flex w-full w-[30%] justify-center rounded-md  py-[12px] text-base font-medium text-white transition duration-200 dark:text-white ${
                status === "CANCELED" ? "bg-orange-500" : "bg-gray-500"
              }`}
              type="button"
              onClick={() => setStatus("CANCELED")}
            >
              Annulé par l'ainé
            </button>
            <button
              className={`linear mr-2 inline-flex w-full w-[30%] justify-center rounded-md  py-[12px] text-base font-medium text-white transition duration-200 dark:text-white ${
                status === "CANCELED_BY_SYSYTEM" ? "bg-red-500" : "bg-gray-500"
              }`}
              type="button"
              onClick={() => setStatus("CANCELED_BY_SYSYTEM")}
            >
              Annulé par le système
            </button>
          </div>
          <ComplexTable
            status={status}
            setOrderHandle={setOrderHandle}
            columnsData={columnsDataComplex}
            tableData={orderRequests}
          />
        </div>
      )}
      <Modal
        showModal={open}
        toogleModal={handleOpen}
        handleSubmit={null}
        order={order}
      ></Modal>
      {countOrderRequests > limit && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countOrderRequests}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default OrderRequests;

import Card from "components/card";
import { MdDelete } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Link } from "react-router-dom";
import SwitchField from "components/fields/SwitchField";
import avatar from "assets/img/avatars/avatar.png";

function TopCreatorTable(props) {
  const { columnsData, tableData, handleStatus, handelRemove } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const onChange = ({ target: { checked } }, row) => {
    handleStatus(row?.original, checked);
  };

  return (
    <Card extra={"pb-6 w-full"}>
      {/* Top Creator Header */}
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pt-4 pb-[20px] shadow-2xl shadow-gray-100 dark:!bg-navy-800 dark:shadow-none"></div>

      {/* Top Creator Heading */}
      <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full min-w-[500px] overflow-x-scroll"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <div className="flex items-center justify-between pt-4 pb-2 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="lg:text-x flex items-center justify-between pt-4 pb-2 text-start uppercase tracking-wide text-gray-600 sm:text-xs">
                    ACTION
                  </div>
                </th>
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="px-4">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "NOM") {
                      data = (
                        <Link
                          key={index}
                          to={"/admin/happyeur/" + row?.original?.id}
                        >
                          <div className="flex items-center gap-2">
                            <div className="h-[30px] w-[30px] rounded-full">
                              <img
                                src={
                                  row?.original?.profile?.photoUrl
                                    ? row?.original?.profile?.photoUrl
                                    : avatar
                                }
                                className="h-full w-full rounded-full"
                                alt=""
                              />
                            </div>
                            <p className="text-black-800 text-sm font-medium dark:text-white">
                              {row?.original?.profile?.name
                                ? row?.original?.profile?.name
                                : row?.original?.profile?.lastName +
                                  " " +
                                  row?.original?.profile?.firstName}
                            </p>
                          </div>
                        </Link>
                      );
                    } else if (cell.column.Header === "EMAIL") {
                      data = (
                        <p className="text-black-800 text-sm font-medium dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "ADRESSE") {
                      data = (
                        <p className="text-black-800 text-sm font-medium dark:text-white">
                          {row?.original?.profile?.address}
                        </p>
                      );
                    } else if (cell.column.Header === "STATUT") {
                      data = (
                        <div className="flex items-center gap-2">
                          <SwitchField
                            id={"status"}
                            label={
                              cell.value === "ACTIVE" ? "Actif" : "Inactif"
                            }
                            onChange={(e) => onChange(e, row)}
                            checked={cell.value === "ACTIVE" ? true : false}
                          />
                        </div>
                      );
                    }
                    return (
                      <td
                        className="py-3 text-sm"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                  <td>
                    <p className="flex">
                      <Link
                        key={index}
                        to={"/admin/happyeur/" + row?.original?.id}
                      >
                        <IoMdEye
                          //  onClick={() => setCategory(row)}
                          className="h-6 w-6 cursor-pointer"
                        />
                      </Link>

                      <div className="w-[20px]" />
                      <MdDelete
                        onClick={() => handelRemove(row?.original)}
                        className="h-6 w-6 cursor-pointer"
                      />
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default TopCreatorTable;

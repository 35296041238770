import { gql } from "@apollo/client";

export const ORDERS = gql`
  query orders(
    $limit: Int!
    $skip: Int!
    $value: String
    $service: ID
    $professional: ID
    $user: ID
  ) {
    orders(
      limit: $limit
      skip: $skip
      value: $value
      service: $service
      professional: $professional
      user: $user
    ) {
      id
      code
      professional {
        id
        profile {
          name
          lastName
          firstName
          photoUrl
        }
        email
        mobile
      }
      user {
        id
        profile {
          name
          lastName
          firstName
          photoUrl
        }
        email
        mobile
      }
      address {
        address
      }
      service {
        id
        name
      }
      status
      amount
      subTotal
      totalAmount
      note
      orderDate
      ratingDone
      rejectedReason
      rejectedAt
      rejected
      createdAt
    }
  }
`;
export const COUNT_ORDERS = gql`
  query countOrders(
    $value: String
    $service: ID
    $professional: ID
    $user: ID
  ) {
    countOrders(
      value: $value
      service: $service
      professional: $professional
      user: $user
    )
  }
`;
export const ORDER_STAT_CITY = gql`
  query ordersStatPerCity {
    ordersStatPerCity {
      values
      counts
    }
  }
`;

export const ORDER_STAT_SERVICE = gql`
  query ordersStatPerService {
    ordersStatPerService {
      values
      counts
    }
  }
`;

export const CHANGE_ORDER_STATUS = gql`
  mutation changeOrderStatus($id: ID!, $status: String!) {
    changeOrderStatus(status: $status, id: $id) {
      id
      status
    }
  }
`;

import { useMutation, useQuery } from "@apollo/client";
import {
  PROFESSIONALS,
  PROFESSIONAL,
  COUNT_PROFESSIONALS,
  UPDATE_PROFESSIONAL,
  REMOVE_PROFESSIONAL,
  CHANGE_PROFESSIONAL_STATUS,
  SET_JUSTIFICATION_DATE,
} from "graphQl/tags";

export const useQueryProfessionals = (
  skip,
  limit,
  search = null,
  service = null
) => {
  const { data, loading, refetch } = useQuery(PROFESSIONALS, {
    variables: {
      skip,
      limit,
      search,
      service,
    },
    fetchPolicy: "no-cache",
  });
  return {
    professionals: data?.professionals,
    loading,
    refetch,
  };
};
export const useQueryProfessional = (id) => {
  const { data, loading, refetch, error } = useQuery(PROFESSIONAL, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });
  return {
    professional: data?.professional,
    loading,
    refetch,
    error,
  };
};
export const useQueryCountProfessionals = (search = null, service = null) => {
  const { data, loading, refetch } = useQuery(COUNT_PROFESSIONALS, {
    variables: {
      search,
      service,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countProfessionals: data?.countProfessionals,
    loading,
    refetch,
  };
};
export const useMutationProfessional = () => {
  const [removeProfessional] = useMutation(REMOVE_PROFESSIONAL);
  const [updateProfessional] = useMutation(UPDATE_PROFESSIONAL);
  const [changeProfessionalStatus] = useMutation(CHANGE_PROFESSIONAL_STATUS);
  const [setJustificationDate] = useMutation(SET_JUSTIFICATION_DATE);

  function deleteProfessionalHandler({ id }) {
    return removeProfessional({
      variables: {
        id,
      },
    });
  }
  function updateProfessionalHandler({ input, id }) {
    return updateProfessional({
      variables: {
        id,
        input,
      },
    });
  }
  function changeProfessionalStatusHandler({ status, id }) {
    return changeProfessionalStatus({
      variables: {
        id,
        status,
      },
    });
  }
  function setJustificationDateHandler({ justificationDate, id }) {
    return setJustificationDate({
      variables: {
        id,
        justificationDate,
      },
    });
  }
  return {
    updateProfessionalHandler,
    deleteProfessionalHandler,
    changeProfessionalStatusHandler,
    setJustificationDateHandler,
  };
};

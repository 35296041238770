import { useState } from "react";
import { BASE_URL } from "../../../variables/constants";
import axios from "axios";
import { toast } from "react-toastify";
import InputField from "components/fields/InputField";
import { IoMdEye } from "react-icons/io";

export default function ResetPassword({ user, setLogin, token }) {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [state, setState] = useState({
    passwordError: null,
    ConfirmPasswordError: null,
    loading: false,
    showConfirmPassword: false,
    showPassword: false,
    passwordError: null,
    confirmPasswordError: null,
  });

  const validate = () => {
    setState({ ...state, passwordError: null, confirmPasswordError: null });
    if (password.length < 5) {
      setState({ ...state, passwordError: "Mot de passe est trop court" });
      return false;
    }
    if (password != confirmPassword) {
      setState({
        ...state,
        confirmPasswordError: "Les mots de passe doivent être indentiques",
      });
      return false;
    }
    return true;
  };
  const onChange = ({ target: { name, value } }) => {
    if (name === "confirmPassword") setConfirmPassword(value);
    if (name === "password") setPassword(value);
  };
  const handleSubmit = () => {
    if (!state.loading && validate()) {
      setState({ ...state, loading: true });
      const data = {
        email: user.email,
        reset_password_token: token,
        password: password,
      };
      axios
        .post(BASE_URL + "/auth/password/reset", data)
        .then((response) => {
          setState({ ...state, loading: false });
          if (response.data.success) {
            toast.success("Votre mot de passe est mis à jour avec succés");
            setLogin();
          } else {
            toast.warn(
              "Votre code peut être expiré, Veuillez renvoyer un nouveau code"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setState({ ...state, loading: false });
        });
    }
  };

  const showPasswordHandle = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const showConfirmPasswordHandle = () => {
    setState({ ...state, showConfirmPassword: !state.showConfirmPassword });
  };

  const { loading, showConfirmPassword, showPassword } = state;
  return (
    <div className="items-right mt-16 mb-16 flex h-full w-full justify-end px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-end lg:justify-end">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-primary-800 dark:text-white">
          Mot de passe oublié
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Réinitialisation de mot de passe
        </p>
        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Mot de passe*"
          placeholder="Min. 8 caractères"
          id="password"
          type={showPassword ? "text" : "password"}
          onChange={onChange}
          icon={
            <IoMdEye
              onClick={showPasswordHandle}
              className="ml-2 mt-5 h-6 w-6 cursor-pointer"
            />
          }
        />

        {state.passwordError && <span>{state.password}</span>}
        {/* ChecPAssword */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Confimer le mot de passe*"
          placeholder="Min. 8 caractères"
          id="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          onChange={onChange}
          icon={
            <IoMdEye
              onClick={showConfirmPasswordHandle}
              className="ml-2 mt-5 h-6 w-6 cursor-pointer"
            />
          }
        />

        {state.confirmPasswordError && (
          <span>{state.confirmPasswordError}</span>
        )}
        <button
          onClick={handleSubmit}
          className="linear mt-2 w-full rounded-xl bg-[#094cbb] py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Envoyer
        </button>
        <div className="mb-6 mt-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
          <p className="text-base text-gray-600 dark:text-white"> ou </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
        </div>
        <div className="mt-4">
          <span className=" text-sm font-medium text-primary-800 dark:text-gray-600">
            Déjà un admin
          </span>
          <button
            className="ml-6 text-sm font-medium text-[#094cbb] hover:text-brand-600 dark:text-white"
            onClick={setLogin}
          >
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
}

export default function Modal({
  showModal,
  toogleModal,
  handleSubmit,
  title,
  children,
}) {
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none backdrop-blur-sm backdrop-contrast-50 focus:outline-none">
            <div className="relative my-6 mx-auto w-[50%] max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="border-blueGray-200 align-center flex content-center items-center justify-center rounded-t p-5 text-center">
                  <h3 className="text-md font-semibold">{title}</h3>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">{children}</div>
                {/*footer*/}
                <div className="border-blueGray-200 flex items-center justify-end rounded-b  p-6">
                  <button
                    className="linear mr-2 inline-flex w-full w-[30%] justify-center rounded-md bg-gray-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
                    type="button"
                    onClick={toogleModal}
                  >
                    Annuler
                  </button>
                  <button
                    className="linear mr-2 inline-flex w-full w-[30%] justify-center rounded-md bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
        </>
      ) : null}
    </>
  );
}

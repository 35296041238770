import { gql } from "@apollo/client";

export const MY_PROFILE = gql`
  query myProfile {
    myProfile {
      id
      profile {
        lastName
        firstName
        photoUrl
        country
        city
        address
      }
      email
      mobile
      mobileVerified
      emailVerified
      status
      role
      createdAt
    }
  }
`;

export const USERS = gql`
  query users($limit: Int!, $skip: Int!, $search: String) {
    users(limit: $limit, skip: $skip, search: $search) {
      id
      profile {
        name
        firstName
        lastName
        photoUrl
        justification
        vitalCard
        bio
        birthday
        justificationDate
        city
        zipCode
        address
        sexe
      }
      email
      mobile
      status
      type
      createdAt
    }
  }
`;

export const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      profile {
        name
        firstName
        lastName
        photoUrl
        justification
        vitalCard
        bio
        birthday
        justificationDate
        city
        zipCode
        address
        sexe
      }
      type
      email
      mobile
      status
      createdAt
    }
  }
`;

export const COUNT_USERS = gql`
  query countUsers($search: String) {
    countUsers(search: $search)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $file: Upload, $input: UserInput!) {
    updateUser(id: $id, file: $file, input: $input) {
      success
      message
    }
  }
`;

export const CHANGE_USER_STATUS = gql`
  mutation changeUserStatus($id: ID!, $status: String) {
    changeUserStatus(id: $id, status: $status) {
      success
      message
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($userId: ID!) {
    removeUser(userId: $userId) {
      success
      message
    }
  }
`;

import React from "react";

export default function SwitchField({ label, id, checked = false, onChange }) {
  return (
    <div>
      <label className="inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          id={id}
          name={id}
          class="peer sr-only"
          checked={checked}
          onChange={onChange}
        />
        <div className="after:start-[2px] peer relative h-5 w-10 rounded-full bg-gray-200 after:absolute after:top-0.5 after:ml-1 after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-green-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-green-800"></div>
        <span
          className={`text-sm ${
            checked ? "font-bold text-green-600 " : "font-medium text-gray-900 "
          } ms-3 dark:text-gray-300`}
        >
          {label}
        </span>
      </label>
    </div>
  );
}

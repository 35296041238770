import { useMutation, useQuery } from "@apollo/client";
import {
  SERVICES,
  COUNT_SERVICES,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  REMOVE_SERVICE,
} from "graphQl/tags";

export const useQueryServices = (
  skip,
  limit,
  value = null,
  category = null
) => {
  const { data, loading, refetch } = useQuery(SERVICES, {
    variables: {
      skip,
      limit,
      value,
      category,
    },
    fetchPolicy: "no-cache",
  });
  return {
    services: data?.services,
    loadingService: loading,
    refetchService: refetch,
  };
};
export const useQueryCountServices = (value) => {
  const { data, loading, refetch } = useQuery(COUNT_SERVICES, {
    variables: {
      value,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countServices: data?.countServices,
    loading,
    refetch,
  };
};
export const useMutationService = () => {
  const [createService] = useMutation(CREATE_SERVICE);
  const [removeService] = useMutation(REMOVE_SERVICE);
  const [updateService] = useMutation(UPDATE_SERVICE);
  function addServiceHandler({ service }) {
    return createService({
      variables: {
        service,
      },
    });
  }

  function deleteServiceHandler({ id }) {
    return removeService({
      variables: {
        id,
      },
    });
  }
  function updateServiceHandler({ service, id }) {
    return updateService({
      variables: {
        id,
        service,
      },
    });
  }
  return {
    addServiceHandler,
    updateServiceHandler,
    deleteServiceHandler,
  };
};

import { gql } from "@apollo/client";

export const CATEGORIES = gql`
  query categories($limit: Int!, $skip: Int!, $value: String) {
    categories(limit: $limit, skip: $skip, value: $value) {
      id
      name
      status
      createdAt
    }
  }
`;
export const COUNT_CATEGORIES = gql`
  query countCategories($value: String) {
    countCategories(value: $value)
  }
`;
export const CREATE_CATEGORY = gql`
  mutation createCategory($category: CategoryInput!) {
    createCategory(category: $category) {
      id
      name
      status
      createdAt
    }
  }
`;
export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: ID!, $category: CategoryInput!) {
    updateCategory(category: $category, id: $id) {
      id
      name
      status
      createdAt
    }
  }
`;
export const REMOVE_CATEGORY = gql`
  mutation removeCategory($id: ID!) {
    removeCategory(id: $id) {
      success
      message
    }
  }
`;

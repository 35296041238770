import { BASE_URL } from "variables/constants";
import axios from "variables/axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getRequest = (body = null, method = "POST") => {
  const request = {
    headers,
    method,
  };
  const token = localStorage.getItem("token-pappy");
  if (token) request.headers.Authorization = token;
  if (body && method === "POST") request.body = JSON.stringify(body);
  return request;
};
export const signin = (params) => {
  const request = getRequest(params);
  return fetch(`${BASE_URL}/auth/signin`, request).then((res) => res.json());
};

export const checkEmail = (params) => {
  const request = getRequest(params);
  return fetch(`${BASE_URL}/auth/check/email`, request).then((res) =>
    res.json()
  );
};

export const checkToken = (token) => {
  axios.defaults.headers.authorization = token;
  return axios
    .post("/auth/check-token", {})
    .then((res) => {
      if (res?.data?.success) return true;
      else return false;
    })
    .catch(async (error) => {
      return false;
    });
};

import { gql } from "@apollo/client";

export const ORDERS_REQUEST = gql`
  query orderRequests(
    $limit: Int!
    $skip: Int!
    $status: String
    $value: String
    $service: ID
    $user: ID
  ) {
    orderRequests(
      limit: $limit
      skip: $skip
      status: $status
      value: $value
      service: $service
      user: $user
    ) {
      id
      code
      contactedProfessionals {
        id
        profile {
          name
          lastName
          firstName
          photoUrl
        }
        email
        mobile
      }
      confirmedProfessionals {
        id
        profile {
          name
          lastName
          firstName
          photoUrl
        }
        email
        mobile
      }
      user {
        id
        profile {
          name
          lastName
          firstName
          photoUrl
        }
        email
        mobile
      }
      address {
        address
      }
      service {
        id
        name
      }
      status
      amount
      note
      orderDate
      duration
      currency

      createdAt
    }
  }
`;
export const COUNT_ORDERS_REQUEST = gql`
  query countOrderRequests($value: String, $service: ID, $user: ID) {
    countOrderRequests(value: $value, service: $service, user: $user)
  }
`;

import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Services from "views/admin/services";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";
import Categories from "views/admin/categories";
import Professional from "views/admin/professional";
import ProfessionalProfile from "views/admin/professionalProfile";
import User from "views/admin/user";
import UserProfile from "views/admin/userProfile";
import Orders from "views/admin/orders";
import Gifts from "views/admin/gifts";
import OrderRequests from "views/admin/orderRequest";
// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdDesignServices,
  MdBarChart,
  MdPerson,
  MdLock,
  MdCategory,
  MdDocumentScanner,
  MdRequestQuote,
  MdCardGiftcard,
} from "react-icons/md";
import { FaUserTie, FaUser } from "react-icons/fa";

const routes = [
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    visible: true,
  },
  {
    name: "Catégories",
    layout: "/admin",
    path: "categories",
    icon: <MdCategory className="h-6 w-6" />,
    component: <Categories />,
    secondary: true,
    visible: true,
  },
  {
    name: "Services",
    layout: "/admin",
    path: "services",
    icon: <MdDesignServices className="h-6 w-6" />,
    component: <Services />,
    secondary: true,
    visible: true,
  },

  {
    name: "Happyeurs",
    layout: "/admin",
    path: "happyeurs",
    icon: <FaUserTie className="h-6 w-6" />,
    component: <Professional />,
    secondary: true,
    visible: true,
  },
  {
    name: "Ainés",
    layout: "/admin",
    path: "aines",
    icon: <FaUser className="h-6 w-6" />,
    component: <User />,
    secondary: true,
    visible: true,
  },
  {
    name: "Demandes",
    layout: "/admin",
    path: "requests",
    icon: <MdRequestQuote className="h-6 w-6" />,
    component: <OrderRequests />,
    secondary: true,
    visible: true,
  },
  {
    name: "Missions",
    layout: "/admin",
    path: "ordres",
    icon: <MdDocumentScanner className="h-6 w-6" />,
    component: <Orders />,
    secondary: true,
    visible: true,
  },
  {
    name: "Cadeaux",
    layout: "/admin",
    path: "gifts",
    icon: <MdCardGiftcard className="h-6 w-6" />,
    component: <Gifts />,
    secondary: true,
    visible: true,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
    visible: false,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
    visible: false,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    visible: false,
  },
  {
    name: "Happyeur",
    layout: "/admin",
    path: "happyeur/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <ProfessionalProfile />,
    visible: false,
  },
  {
    name: "Ainé",
    layout: "/admin",
    path: "aine/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <UserProfile />,
    visible: false,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    visible: false,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "rtl",
    icon: <MdHome className="h-6 w-6" />,
    component: <RTLDefault />,
    visible: false,
  },
];
export default routes;

import { useState, useEffect } from "react";
import ComplexTable from "./components/ComplexTable";
import {
  useQueryGifts,
  useQueryCountGifts,
  useMutationGift,
} from "graphQl/hooks";
import Modal from "./components/Modal";
import Pagination from "components/pagination/Pagination";
import { useAdminContext } from "layouts/admin";
import { toast } from "react-toastify";

export const columnsDataComplex = [
  {
    Header: "NUMÉRO",
    accessor: "code",
  },
  {
    Header: "HAPPYEUR",
    accessor: "professional",
  },
  {
    Header: "CADEAU",
    accessor: "name",
  },
  {
    Header: "STATUT",
    accessor: "status",
  },
];

const Gifts = ({ professional }) => {
  const { search } = useAdminContext();

  const [open, setOpen] = useState(false);
  const [gift, setGift] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(professional ? 9999 : 10);
  const { gifts, loadingGifts, refetchGifts } = useQueryGifts(
    skip,
    limit,
    search,
    professional
  );
  const { countGifts, loading, refetch } = useQueryCountGifts(
    search,
    professional
  );
  const { changeGiftStatusHandler } = useMutationGift();
  const clear = () => {
    setGift(null);
  };
  const handleOpen = () => {
    if (open) clear();
    setOpen(!open);
    if (gift) clear();
  };
  const setGiftHandle = ({ original }) => {
    setGift(original);
    handleOpen();
  };
  const changeStats = (status) => {
    changeGiftStatusHandler({
      id: gift?.id,
      status,
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Statut du cadeau a été modifié avec succès");
        refetch();
        refetchGifts();
      }
    });
    handleOpen();
  };

  useEffect(() => {}, [skip, limit, countGifts, gifts]);
  return (
    <div>
      {gifts && !loadingGifts && (
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <ComplexTable
            setGiftHandle={setGiftHandle}
            columnsData={columnsDataComplex}
            tableData={gifts}
          />
        </div>
      )}
      <Modal
        showModal={open}
        toogleModal={handleOpen}
        handleSubmit={null}
        gift={gift}
        changeStats={changeStats}
      ></Modal>
      {countGifts > limit && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countGifts}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default Gifts;

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import client from "variables/apolloClient";
import RtlLayout from "layouts/rtl";
import { AdminViewProvider } from "layouts/admin";
import AuthLayout from "layouts/auth";
import { ApolloProvider } from "@apollo/client";

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminViewProvider />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </ApolloProvider>
  );
};

export default App;

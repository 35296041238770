import { useState, useEffect, Fragment } from "react";
import CheckTable from "./components/CheckTable";
import Modal from "./components/Modal";
import ModalService from "./components/ModalService";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import { PlusIcon } from "@heroicons/react/20/solid";
import {
  useMutationCategory,
  useQueryCategories,
  useMutationService,
  useQueryServices,
  useQueryCountServices,
} from "graphQl/hooks";
import { toast } from "react-toastify";
import Pagination from "components/pagination/Pagination";
import { useAdminContext } from "layouts/admin";

const columnsDataCheck = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "CATÉGORIE",
    accessor: "category",
  },
  {
    Header: "DATE DE CRÉATION",
    accessor: "createdAt",
  },
  {
    Header: "STATUT",
    accessor: "status",
  },
];

const Services = () => {
  const { search } = useAdminContext();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [filter, setFilter] = useState(null);
  const [status, setStatus] = useState("ACTIVE");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const { addCategoryHandler } = useMutationCategory();
  const { addServiceHandler, updateServiceHandler, deleteServiceHandler } =
    useMutationService();
  const { categories, loading, refetch } = useQueryCategories(skip, limit);
  const { services, loadingService, refetchService } = useQueryServices(
    skip,
    limit,
    search,
    filter
  );
  const { countServices } = useQueryCountServices(search);

  const handleOpen = () => {
    if (open) clear();
    setOpen(!open);
  };
  const handleOpenModal = () => {
    if (openModal) clear();
    setOpenModal(!openModal);
  };

  const setService = ({ original: { name, status, id, category } }) => {
    setName(name);
    setStatus(status);
    setId(id);
    setCategoryId(category?.id);
    handleOpenModal();
  };

  const clear = () => {
    setName(null);
    setStatus("ACTIVE");
    setId(null);
    setCategoryId(null);
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "name") setName(value);
    if (name === "status") setStatus(value);
    if (name === "categoryId") setCategoryId(value);
    if (name === "filter")
      if (value.includes("Filter")) setFilter(null);
      else setFilter(value);
  };
  useEffect(() => {}, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      return toast.error("errur");
    }
    addCategoryHandler({
      category: {
        name,
        status,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Catégorie ajoutée avec succès");
        handleOpen();
        clear();
        refetch();
      }
    });
  };
  const handleStatus = (row, value) => {
    updateServiceHandler({
      id: row?.id,
      service: {
        name: row?.name,
        status: value ? "ACTIVE" : "INACTIVE",
        category: row?.category?.id,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success(
          "Statut du service " + row?.name + " modifié avec succès"
        );
        clear();
        refetch();
        refetchService();
      }
    });
  };
  const handleSubmitService = (e) => {
    e.preventDefault();
    if (!name && !categoryId) {
      return toast.error("errur");
    }
    addServiceHandler({
      service: {
        name,
        status,
        category: categoryId,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Service ajouté avec succès");
        handleOpenModal();
        clear();
        refetch();
        refetchService();
      }
    });
  };

  const handleEditService = (e) => {
    e.preventDefault();
    if (!name && !id && !categoryId) {
      return toast.error("errur");
    }
    updateServiceHandler({
      id,
      service: {
        name,
        status,
        category: categoryId,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Service modifié avec succès");
        handleOpenModal();
        clear();
        refetch();
        refetchService();
      }
    });
  };

  const handleRemoveService = ({ original: { id } }) => {
    if (!id) {
      return toast.error("errur");
    }
    deleteServiceHandler({
      id,
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Service supprimé avec succès");
        clear();
        refetch();
        refetchService();
      }
    });
  };
  return (
    <div>
      <div className="mt-2 flex w-full content-end items-end justify-end">
        <div className="mt-2 flex w-full content-end items-end justify-end">
          <SelectField
            variant="auth"
            extra="mr-3 flex justify-center align-center content-center items-center"
            extraLabel="mr-3 mt-1"
            label="Catégorie"
            placeholder="Catégorie"
            id="filter"
            onChange={onChange}
            value={filter}
            defaultValue={filter}
          >
            <option selected={filter === null} value={null}>
              Filter par une catégorie
            </option>
            {categories?.map((cat) => (
              <option selected={categoryId === cat?.id} value={cat?.id}>
                {cat?.name}
              </option>
            ))}
          </SelectField>
          <button
            onClick={handleOpen}
            className="linear mr-2 inline-flex w-full w-[20%] justify-center rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
          >
            <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
            Créer une catégorie
          </button>
          <button
            onClick={handleOpenModal}
            className="linear inline-flex w-[20%] w-full justify-center rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
            Créer un service
          </button>
        </div>
      </div>
      <div className="mt-5 grid h-full ">
        {!loadingService && services && (
          <CheckTable
            setService={setService}
            handleRemove={handleRemoveService}
            columnsData={columnsDataCheck}
            tableData={services}
            handleStatus={handleStatus}
          />
        )}
      </div>
      <Modal
        showModal={open}
        toogleModal={handleOpen}
        handleSubmit={handleSubmit}
        title={"AJOUTER UNE CATÉGORIE"}
      >
        <div>
          <InputField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center w-full"
            label="Nom*"
            placeholder="Nom de la catégorie"
            id="name"
            extraLabel="mr-4"
            type="text"
            defaultValue={name}
            value={name}
            onChange={onChange}
          />
          <SelectField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            extraLabel="mr-2"
            label="Statut*"
            placeholder="Satut"
            id="status"
            onChange={onChange}
            value={status}
            defaultValue={status}
          >
            <option selected={status === "ACTIVE"} value="ACTIVE">
              Actif
            </option>
            <option selected={status === "INACTIVE"} value="INACTIVE">
              Inactif
            </option>
          </SelectField>
        </div>
      </Modal>
      <ModalService
        showModal={openModal}
        toogleModal={handleOpenModal}
        handleSubmit={id ? handleEditService : handleSubmitService}
        title={id ? "MODIFIER UN SERVICE" : "AJOUTER UN SERVICE"}
      >
        <div>
          <InputField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            label="Nom*"
            placeholder="Nom du service"
            id="name"
            extraLabel="mr-9"
            type="text"
            defaultValue={name}
            value={name}
            onChange={onChange}
          />
          <SelectField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            extraLabel="mr-2"
            label="Catégorie*"
            placeholder="Catégorie"
            id="categoryId"
            onChange={onChange}
            value={categoryId}
            defaultValue={categoryId}
          >
            <option selected={categoryId === null} value={null}>
              Sélectionner une catégorie
            </option>
            {categories?.map((cat) => (
              <option selected={categoryId === cat?.id} value={cat?.id}>
                {cat?.name}
              </option>
            ))}
          </SelectField>
          <SelectField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            extraLabel="mr-7"
            label="Statut*"
            placeholder="Satut"
            id="status"
            onChange={onChange}
            value={status}
            defaultValue={status}
          >
            <option selected={status === "ACTIVE"} value="ACTIVE">
              Actif
            </option>
            <option selected={status === "INACTIVE"} value="INACTIVE">
              Inactif
            </option>
          </SelectField>
        </div>
      </ModalService>
      {countServices > limit && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countServices}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default Services;

import { useState } from "react";
import { EMAIL_REGEX, BASE_URL } from "../../../variables/constants";
import axios from "axios";
import { toast } from "react-toastify";
import InputField from "components/fields/InputField";

export default function SendResetPasswordCode({ setUser, setLogin }) {
  const [email, setEmail] = useState(null);

  const [state, setState] = useState({
    loading: false,
    errors: {
      email: null,
    },
  });

  const onChange = ({ target: { name, value } }) => {
    if (name === "email") setEmail(value);
  };

  const handleSubmit = () => {
    if (!state.loading) {
      setState({ ...state, loading: true });

      let errors = { email: null };
      if (!EMAIL_REGEX.test(email)) errors.email = "Email invalide";

      if (errors.email) setState({ ...state, errors });
      else {
        axios
          .post(BASE_URL + "/auth/password/reset/code/send", {
            email: email,
          })
          .then((response) => {
            setState({ ...state, loading: false });
            if (response.data.success) {
              setUser(response.data.user);
            } else {
              toast.warn("email incorrect");
            }
          })
          .catch((reason) => {
            console.log(reason);
            setState({ ...state, loading: false });
          });
      }
    }
  };

  const { loading, errors } = state;
  return (
    <div className="items-right mt-16 mb-16 flex h-full w-full justify-end px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-end lg:justify-end">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-primary-800 dark:text-white">
          Mot de passe oublié
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Veuillez saisir l'adresse à laquelle vous souhaitez recevoir l'email
          de réinitialisation du mot de passe.
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@mail.com"
          id="email"
          name="email"
          type="text"
          onChange={onChange}
        />
        {errors?.email && <span>{errors.email}</span>}
        {/* Password */}

        {/* Checkbox */}

        <button
          onClick={handleSubmit}
          className="linear mt-2 w-full rounded-xl bg-[#094cbb] py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Envoyer
        </button>
        <div className="mb-6 mt-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
          <p className="text-base text-gray-600 dark:text-white"> ou </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
        </div>
        <div className="mt-4">
          <span className=" text-sm font-medium text-primary-800 dark:text-gray-600">
            Déjà un admin
          </span>
          <button
            className="ml-6 text-sm font-medium text-[#094cbb] hover:text-brand-600 dark:text-white"
            onClick={setLogin}
          >
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
}

import config from "./config.json";
const bool = false;
const isLocal = bool;
const PROTOCOL = isLocal ? "http://" : "https://";
const WS_PROTOCOL = isLocal ? "ws://" : "wss://";
const HOST = isLocal ? "127.0.0.1:4002" : config.HOST;
const WS_ENDPOINT = "/admin-subscriptions";
const GOOGLE_API_KEY = config.GOOGLE_API_KEY;
const STRIPE_ENDPOINT = "https://api.stripe.com/v1/tokens";
const STRIPE_DASHBORD = "https://dashboard.stripe.com/login";
const STRIPE_SECRET_KEY = "sk_test_X5KuJ3uuq3Ktjg3aQOjoLRdC00cPWoI5ts";
const STRIPE_PUPLIC_KEY = "pk_test_aZ6WqOrDWOMN8H5d3gjy0nyn00FAFiuUSj";
const BASE_URL = `${PROTOCOL}${HOST}`;
const PHOTO_URL = `${PROTOCOL}${HOST}`;
const WS_URL = `${WS_PROTOCOL}${HOST}${WS_ENDPOINT}`;
const DEFAULT_LANGAGE = "fr";
const DASH_URL = config.DASH_URL;

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z0-9 '-]{2,50}$/;
const REAL_NAME_REGEX = /^[a-zA-Z ]{2,50}$/;
const REGEX_MOBILE = /*/^(?:\+216)[0-9]{8}$/; */ /^(?:\+33)[0-9]{9,10}$/;
const MOBILE_REGEX_INCLUSIVE = /^(:?(?:\+|00)33|0)[1-9]{1}[0-9]{8}$/;
const REGEX_MOBILE_NO_PREFIX = /*/^(?:\+216)[0-9]{8}$/; */ /^[0-9]{9}$/;
const PREPARATION_TIME = /^[0-9]{2}$/;

const VAT_REGEX = /^(FR)[0-9]{11}$/;
const SIRET_REGEX = /^[0-9]{14}$/;
const SIREN_REGEX = /^[0-9]{9}$/;

const RIB_REGEX = /^[0-9]{11}$/;
const limits = [10, 20, 40, 60, 80, 100];
const FRENCH_DATE_FORMAT = "DD/MM/YYYY";
const FRENCH_DATE_TIME_FORMAT = `${FRENCH_DATE_FORMAT} [à] HH:mm`;
const userTypes = {
  ASSOCIACTION: "Association",
  ELDER: "Ainé",
  RELATIVE: "Proche",
};

const professionalState = {
  EMPLOYED: "Salarié",
  STUDENT: "Etudiant",
  RETIRED: "Retraité",
  SELFEMPLOYED: "Entrepreneur",
  UNEMPLOYED: "Sans emploi",
};
const INACTIVE = "INACTIVE";
const ACTIVE = "ACTIVE";

export {
  BASE_URL,
  WS_URL,
  PHOTO_URL,
  GOOGLE_API_KEY,
  STRIPE_ENDPOINT,
  STRIPE_SECRET_KEY,
  STRIPE_PUPLIC_KEY,
  DEFAULT_LANGAGE,
  STRIPE_DASHBORD,
  DASH_URL,
  EMAIL_REGEX,
  NAME_REGEX,
  REAL_NAME_REGEX,
  REGEX_MOBILE,
  MOBILE_REGEX_INCLUSIVE,
  REGEX_MOBILE_NO_PREFIX,
  PREPARATION_TIME,
  VAT_REGEX,
  SIRET_REGEX,
  SIREN_REGEX,
  RIB_REGEX,
  limits,
  FRENCH_DATE_TIME_FORMAT,
  userTypes,
  INACTIVE,
  ACTIVE,
  professionalState,
};

import React from "react";
import { MdArrowDropUp } from "react-icons/md";
import Card from "components/card";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";

const TotalSpent = () => {
  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-black-800 text-lg font-bold dark:text-white">
            Revenue
          </h4>
        </div>

        <div className="mb-6 flex items-center justify-center">
          <select className="dark:!bg-black-800 mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:text-white">
            <option value="Mensuel">Mensuel</option>
            <option value="Annuel">Annuel</option>
            <option value="weekly">Hebdomadaire</option>
          </select>
        </div>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="text-black-800 mt-[20px] text-3xl font-bold dark:text-white">
            37.500€
          </p>
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center justify-center">
              <MdArrowDropUp className="font-medium text-green-500" />
              <p className="text-sm font-bold text-green-500"> +2.45% </p>
            </div>
          </div>
        </div>
        <div className="h-full w-full">
          <LineChart
            options={lineChartOptionsTotalSpent}
            series={lineChartDataTotalSpent}
          />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;

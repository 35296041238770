import axios from "axios";
import { BASE_URL } from "./constants";

export default axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  headers: {
    post: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  },
});

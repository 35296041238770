import { useMutation, useQuery } from "@apollo/client";
import { ORDERS_REQUEST, COUNT_ORDERS_REQUEST } from "graphQl/tags";

export const useQueryOrderRequests = (
  skip,
  limit,
  status = "PENDING",
  value = null,
  service = null,
  user = null
) => {
  const { data, loading, refetch } = useQuery(ORDERS_REQUEST, {
    variables: {
      skip,
      limit,
      status,
      value,
      service,
      user,
    },
    fetchPolicy: "no-cache",
  });
  return {
    orderRequests: data?.orderRequests,
    loadingOrders: loading,
    refetchOrders: refetch,
  };
};
export const useQueryCountOrderRequests = (
  status = "PENDING",
  value = null,
  service = null,
  user = null
) => {
  const { data, loading, refetch } = useQuery(COUNT_ORDERS_REQUEST, {
    variables: {
      status,
      value,
      service,
      user,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countOrderRequests: data?.countOrderRequests,
    loading,
    refetch,
  };
};

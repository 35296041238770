import { useMutation, useQuery } from "@apollo/client";
import {
  ORDERS,
  COUNT_ORDERS,
  CHANGE_ORDER_STATUS,
  ORDER_STAT_CITY,
  ORDER_STAT_SERVICE,
} from "graphQl/tags";

export const useQueryOrders = (
  skip,
  limit,
  value = null,
  service = null,
  professional = null,
  user = null
) => {
  const { data, loading, refetch } = useQuery(ORDERS, {
    variables: {
      skip,
      limit,
      value,
      service,
      professional,
      user,
    },
    fetchPolicy: "no-cache",
  });
  return {
    orders: data?.orders,
    loadingOrders: loading,
    refetchOrders: refetch,
  };
};
export const useQueryCountOrders = (
  value = null,
  service = null,
  professional = null,
  user = null
) => {
  const { data, loading, refetch } = useQuery(COUNT_ORDERS, {
    variables: {
      value,
      service,
      professional,
      user,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countOrders: data?.countOrders,
    loading,
    refetch,
  };
};
export const useQueryOrderStatCity = () => {
  const { data, loading, refetch } = useQuery(ORDER_STAT_CITY);
  return {
    ordersStatPerCity: data?.ordersStatPerCity,
    loading,
    refetch,
  };
};
export const useQueryOrderStatService = () => {
  const { data, loading, refetch } = useQuery(ORDER_STAT_SERVICE);
  return {
    ordersStatPerService: data?.ordersStatPerService,
    loading,
    refetch,
  };
};
export const useMutationOrder = () => {
  const [changeOrderStatus] = useMutation(CHANGE_ORDER_STATUS);

  function changeOrderStatusHandler({ status, id }) {
    return changeOrderStatus({
      variables: {
        id,
        status,
      },
    });
  }
  return {
    changeOrderStatusHandler,
  };
};

import { useMutation, useQuery } from "@apollo/client";
import {
  USERS,
  USER,
  COUNT_USERS,
  UPDATE_USER,
  REMOVE_USER,
  MY_PROFILE,
  CHANGE_USER_STATUS,
} from "graphQl/tags";

export const useQueryMyProfile = () => {
  const { data, loading, refetch } = useQuery(MY_PROFILE);
  return {
    myProfile: data?.myProfile,
    loading,
    refetch,
  };
};

export const useQueryUsers = (skip, limit, search = null, service = null) => {
  const { data, loading, refetch } = useQuery(USERS, {
    variables: {
      skip,
      limit,
      search,
      service,
    },
    fetchPolicy: "no-cache",
  });
  return {
    users: data?.users,
    loading,
    refetch,
  };
};
export const useQueryUser = (id) => {
  const { data, loading, refetch, error } = useQuery(USER, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });
  return {
    user: data?.user,
    loading,
    refetch,
    error,
  };
};
export const useQueryCountUsers = (search = null) => {
  const { data, loading, refetch, error } = useQuery(COUNT_USERS, {
    variables: {
      search,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countUsers: data?.countUsers,
    loading,
    refetch,
  };
};
export const useMutationUser = () => {
  const [removeUser] = useMutation(REMOVE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [changeUserStatus] = useMutation(CHANGE_USER_STATUS);

  function deleteUserHandler({ id }) {
    return removeUser({
      variables: {
        userId: id,
      },
    });
  }
  function updateUserHandler({ input, id }) {
    return updateUser({
      variables: {
        id,
        input,
      },
    });
  }
  function changeUserStatusHandler({ status, id }) {
    return changeUserStatus({
      variables: {
        id,
        status,
      },
    });
  }
  return {
    updateUserHandler,
    deleteUserHandler,
    changeUserStatusHandler,
  };
};

import { useState, useEffect } from "react";
import CheckTable from "./components/CheckTable";
import { PlusIcon } from "@heroicons/react/20/solid";
import Modal from "./components/Modal";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import {
  useMutationCategory,
  useQueryCategories,
  useQueryCountCategories,
} from "graphQl/hooks";
import { toast } from "react-toastify";
import Pagination from "components/pagination/Pagination";
import { useAdminContext } from "layouts/admin";

const columnsDataCheck = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "DATE DE CRÉATION",
    accessor: "createdAt",
  },
  {
    Header: "STATUT",
    accessor: "status",
  },
];

const Categories = () => {
  const { search } = useAdminContext();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const { addCategoryHandler, updateCategoryHandler, deleteCategoryHandler } =
    useMutationCategory();
  const { categories, loading, refetch } = useQueryCategories(
    skip,
    limit,
    search
  );
  const { countCategories } = useQueryCountCategories(search);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [status, setStatus] = useState("ACTIVE");

  const handleOpen = () => {
    if (open) clear();
    setOpen(!open);
  };

  const setCategory = ({ original: { name, status, id } }) => {
    setName(name);
    setStatus(status);
    setId(id);
    handleOpen();
  };

  const clear = () => {
    setName(null);
    setStatus("ACTIVE");
    setId(null);
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "name") setName(value);
    if (name === "status") setStatus(value);
  };
  useEffect(() => {}, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      return toast.error("errur");
    }
    addCategoryHandler({
      category: {
        name,
        status,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Catégorie ajoutée avec succès");
        handleOpen();
        clear();
        refetch();
      }
    });
  };
  const handleStatus = (row, value) => {
    updateCategoryHandler({
      id: row?.id,
      category: {
        name: row?.name,
        status: value ? "ACTIVE" : "INACTIVE",
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success(
          "Statut du catégorie " + row?.name + " modifié avec succès"
        );
        clear();
        refetch();
      }
    });
  };
  const handleEdit = (e) => {
    e.preventDefault();
    if (!name && !id) {
      return toast.error("errur");
    }
    updateCategoryHandler({
      id,
      category: {
        name,
        status,
      },
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Catégorie modifiée avec succès");
        handleOpen();
        clear();
        refetch();
      }
    });
  };
  const handleRemove = ({ original: { id } }) => {
    if (!id) {
      return toast.error("errur");
    }
    deleteCategoryHandler({
      id,
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Catégorie supprimée avec succès");
        clear();
        refetch();
      }
    });
  };

  return (
    <div>
      <div className="mt-2 flex w-full content-end items-end justify-end">
        <button
          onClick={handleOpen}
          className="linear mr-2 inline-flex w-full w-[20%] justify-center rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
        >
          <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
          Créer une catégorie
        </button>
      </div>
      <div className="mt-5 grid h-full ">
        {!loading && categories && (
          <CheckTable
            setCategory={setCategory}
            handleRemove={handleRemove}
            columnsData={columnsDataCheck}
            tableData={categories}
            handleStatus={handleStatus}
          />
        )}
      </div>
      <Modal
        showModal={open}
        toogleModal={handleOpen}
        handleSubmit={id ? handleEdit : handleSubmit}
        title={id ? "MODIFIER UNE CATÉGORIE" : "AJOUTER UNE CATÉGORIE"}
      >
        <div>
          <InputField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            label="Nom*"
            placeholder="Nom de la catégorie"
            id="name"
            extraLabel="mr-4"
            type="text"
            defaultValue={name}
            value={name}
            onChange={onChange}
          />
          <SelectField
            variant="auth"
            extra="mb-3 flex justify-center align-center content-center items-center"
            extraLabel="mr-2"
            label="Statut*"
            placeholder="Satut"
            id="status"
            onChange={onChange}
            value={status}
            defaultValue={status}
          >
            <option selected={status === "ACTIVE"} value="ACTIVE">
              Actif
            </option>
            <option selected={status === "INACTIVE"} value="INACTIVE">
              Inactif
            </option>
          </SelectField>
        </div>
      </Modal>
      {countCategories > limit && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countCategories}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default Categories;

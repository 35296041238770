import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { signin } from "api/auth";
import { EMAIL_REGEX } from "variables/constants";

export default function SignIn({ onSuccess, setForgotPasword }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errors, setErrors] = useState({ email: null, password: null });

  const handleSubmit = () => {
    let errors = { email: null, password: null };
    if (!EMAIL_REGEX.test(email)) errors.email = "Email invalid";
    if (password.length < 6) errors.password = "Password invalid";
    if (errors.email || errors.password) setErrors(errors);
    else {
      setLoading(true);
      signin({ email, password })
        .then(({ success, token, message }) => {
          if (!success) {
            setLoading(false);
          } else {
            localStorage.setItem("token-pappy", "Bearer " + token);
            onSuccess(token);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };

  return (
    <div className="items-right mt-16 mb-16 flex h-full w-full justify-end px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-end lg:justify-end">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-primary-800 dark:text-white">
          Connexion
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Entrez votre email et votre mot de passe pour vous connecter !
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@mail.com"
          id="email"
          type="text"
          name="email"
          onChange={onChange}
        />
        {errors?.email && <span>{errors.email}</span>}
        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Mot de passe*"
          placeholder="Min. 8 caractères"
          id="password"
          name="password"
          type="password"
          onChange={onChange}
        />
        {errors?.password && <span>{errors.password}</span>}

        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-primary-800 dark:text-white">
              Rester connecté
            </p>
          </div>
          <button
            className="text-sm font-medium text-[#094cbb] hover:text-blue-600 dark:text-white"
            onClick={setForgotPasword}
          >
            Mot de passe oublié?
          </button>
        </div>
        <button
          onClick={handleSubmit}
          className="linear mt-2 w-full rounded-xl bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-blue-400 dark:text-white dark:hover:bg-blue-300 dark:active:bg-blue-200"
        >
          Se connecter
        </button>
        {/*  <div className="mb-6 mt-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
        </div>
        <div className="mt-4">
          <span className=" text-sm font-medium text-primary-800 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-blue-500 hover:text-blue-600 dark:text-white"
          >
            Créer un compte{" "}
          </a>
        </div> */}
      </div>
    </div>
  );
}

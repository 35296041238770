import { useState } from "react";
import { BASE_URL } from "../../../variables/constants";
import axios from "axios";
import { toast } from "react-toastify";
import InputField from "components/fields/InputField";
export default function SignIn({ user, setLogin, setToken }) {
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errors, setErrors] = useState({ code: null, password: null });

  const handleSubmit = () => {
    let errors = { code: null };
    if (!code) errors.code = "Code invalid";
    if (errors.code) setErrors(errors);
    else {
      setLoading(true);
      const data = {
        email: user.email,
        reset_password_code: code,
      };
      axios
        .post(BASE_URL + "/auth/password/reset/code/check", data)
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setToken(response.data.reset_password_token);
          } else {
            toast.warn(
              "Votre code peut être expiré, Veuillez envoyer un nouveau code"
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "code") setCode(value);
  };

  return (
    <div className="items-right mt-16 mb-16 flex h-full w-full justify-end px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-end lg:justify-end">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-primary-800 dark:text-white">
          Mot de passe oublié
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Veuillez saisir le code de réinitialisation que vous avez reçu par
          email.
        </p>

        {/* Code */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Code*"
          placeholder="123456"
          id="code"
          name="code"
          type="text"
          onChange={onChange}
        />
        {errors?.code && <span>{errors.code}</span>}
        {/* Password */}

        {/* Checkbox */}

        <button
          onClick={handleSubmit}
          className="linear mt-2 w-full rounded-xl bg-[#094cbb] py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Envoyer
        </button>
        <div className="mb-6 mt-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
          <p className="text-base text-gray-600 dark:text-white"> ou </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-800" />
        </div>
        <div className="mt-4">
          <span className=" text-sm font-medium text-primary-800 dark:text-gray-600">
            Déjà un admin
          </span>
          <button
            className="ml-6 text-sm font-medium text-[#094cbb] hover:text-brand-600 dark:text-white"
            onClick={setLogin}
          >
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
}

import { useEffect } from "react";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import SelectField from "../fields/SelectField";

function Pagination({ length, skip, limit, setSkip, setLimit }) {
  function getPages() {
    if (length === 0) return 1;
    let pages = parseInt(length / limit);
    if (length % limit !== 0) pages++;
    return pages;
  }

  function getCurrentPage() {
    if (length === 0 || skip === 0) return 1;
    let current = parseInt(skip / limit);
    return current + 1;
  }

  const next = () => {
    if (getCurrentPage() === getPages()) return;
    setSkip(skip + limit);
  };

  const prev = () => {
    if (skip === 0) return;
    setSkip(skip - limit);
  };
  const onChange = ({ target: { name, value } }) => {
    if (name === "limit") setLimit(parseInt(value));
  };
  useEffect(() => {}, [skip, limit, length]);

  return (
    <div className="mt-6 flex justify-between">
      <SelectField
        variant="auth"
        extra="mr-3 flex justify-center align-center content-center items-center"
        extraLabel="mr-3 mt-1 w-[200px]"
        label="Items par pages"
        placeholder="Items par pages"
        id="limit"
        onChange={onChange}
        value={limit}
        defaultValue={limit}
      >
        <option key={10} selected={limit === 10} value={10}>
          10
        </option>
        <option key={20} selected={limit === 20} value={20}>
          20
        </option>
        <option key={30} selected={limit === 30} value={30}>
          30
        </option>
        <option key={40} selected={limit === 40} value={40}>
          40
        </option>
        <option key={50} selected={limit === 50} value={50}>
          50
        </option>
        <option key={100} selected={limit === 100} value={100}>
          100
        </option>
      </SelectField>
      <div className="flex-end  mr-2 flex items-center justify-end gap-4 ">
        <button
          className="flex items-center gap-2 rounded-full  dark:text-white "
          onClick={prev}
          disabled={skip === 0}
        >
          <IoMdArrowBack strokeWidth={2} className="h-4 w-4" /> Précedent
        </button>
        <div className="flex items-center gap-2">
          <div className="border-gray flex h-[45px] w-[45px] items-center justify-center rounded-[8px] border  dark:text-white ">
            {getCurrentPage() + "/" + getPages()}
          </div>
        </div>
        <button
          className="flex items-center gap-2 rounded-full dark:text-white "
          onClick={next}
          disabled={getCurrentPage() === getPages()}
        >
          Suivant
          <IoMdArrowForward strokeWidth={2} className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
}
export default Pagination;

import { gql } from "@apollo/client";

export const GIFTS = gql`
  query gifts($limit: Int!, $skip: Int!, $value: String, $professional: ID) {
    gifts(
      limit: $limit
      skip: $skip
      value: $value
      professional: $professional
    ) {
      id
      professional {
        id
        profile {
          name
          lastName
          firstName
          photoUrl
        }
        email
        mobile
      }
      name
      status
      giftPoints
      approvedAt
      createdAt
    }
  }
`;
export const COUNT_GIFTS = gql`
  query countGifts($value: String, $professional: ID) {
    countGifts(value: $value, professional: $professional)
  }
`;
export const CHANGE_GIFT_STATUS = gql`
  mutation changeGiftStatus($id: ID!, $status: String!) {
    changeGiftStatus(status: $status, id: $id) {
      id
      status
    }
  }
`;

import React from "react";
import Card from "components/card";

const Project = ({ professional }) => {
  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Services offerts
        </h4>
      </div>
      {/* Project 1 */}
      <div className="grid w-full grid-cols-6 items-center justify-start rounded-2xl  dark:!bg-navy-700 dark:shadow-none">
        {professional?.services?.map((it) => (
          <div
            key={Math.random()}
            className="mt-2 mb-2 ml-2 mr-2 flex w-[95%] flex-col items-center rounded-md bg-gray-200 p-2 text-center dark:!bg-navy-600"
          >
            <span className="text-center text-base font-medium text-navy-700 dark:text-white">
              {it?.name}
            </span>
            <span className="font-sm text-center text-navy-700 dark:text-white">
              ({it?.category?.name})
            </span>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Project;

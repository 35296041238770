import { gql } from "@apollo/client";

export const PROFESSIONALS = gql`
  query professionals(
    $limit: Int!
    $skip: Int!
    $search: String
    $service: String
  ) {
    professionals(
      limit: $limit
      skip: $skip
      search: $search
      service: $service
    ) {
      id
      profile {
        name
        firstName
        lastName
        photoUrl
        justification
        vitalCard
        bio
        birthday
        justificationDate
        city
        zipCode
        address
        sexe
        professionalState
        function
      }
      email
      mobile
      status
      createdAt
    }
  }
`;

export const PROFESSIONAL = gql`
  query professional($id: ID!) {
    professional(id: $id) {
      id
      profile {
        name
        firstName
        lastName
        photoUrl
        justification
        vitalCard
        bio
        birthday
        justificationDate
        city
        zipCode
        address
        sexe
        professionalState
        function
      }
      services {
        id
        name
        category {
          id
          name
        }
      }
      serviceHours
      serviceHoursConverted
      giftPoints
      email
      mobile
      status
      createdAt
    }
  }
`;

export const COUNT_PROFESSIONALS = gql`
  query countProfessionals($search: String, $service: String) {
    countProfessionals(search: $search, service: $service)
  }
`;

export const UPDATE_PROFESSIONAL = gql`
  mutation updateProfessional(
    $id: ID!
    $file: Upload
    $input: ProfessionalInput!
  ) {
    updateProfessional(id: $id, file: $file, input: $input) {
      success
      message
    }
  }
`;

export const CHANGE_PROFESSIONAL_STATUS = gql`
  mutation changeProfessionalStatus($id: ID!, $status: String) {
    changeProfessionalStatus(id: $id, status: $status) {
      success
      message
    }
  }
`;

export const REMOVE_PROFESSIONAL = gql`
  mutation removeProfessional($id: ID!) {
    removeProfessional(id: $id) {
      success
      message
    }
  }
`;

export const SET_JUSTIFICATION_DATE = gql`
  mutation setJustificationDate($id: ID!, $justificationDate: Float!) {
    setJustificationDate(id: $id, justificationDate: $justificationDate) {
      success
      message
    }
  }
`;

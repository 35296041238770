import {
  ApolloClient,
  concat,
  InMemoryCache,
  split,
  HttpLink,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";
import { BASE_URL, WS_URL } from "./constants";
import Token from "./Token";

// Create an http link:
const httpLink = new HttpLink({
  uri: BASE_URL + "/api",
});
const authMiddleware = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    authorization: localStorage.getItem("token-pappy"), // token.replace(new RegExp('"', "g"), "")
  },
}));
// Create a WebSocket link:
const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URL,
    options: { reconnect: true },
    connectionParams: async () => ({
      token: Token.getToken(),
    }),
  })
);
const retryLink = new RetryLink();
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message) {
        const [f, msg, x] = message.split('"');
        // console.log(msg);
        toast.error(msg || message);
      }
      console.dir(message);
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: new InMemoryCache(),
});

export default client;

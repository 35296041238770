import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import Card from "components/card";

const PieChartCard = ({ ordersStatPerService }) => {
  const pieChartOptions = {
    labels: ordersStatPerService?.values,
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },

    tooltip: {
      enabled: true,
      theme: "dark",
      fillSeriesColor: false,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000",
      },
    },
  };

  const pieChartData = ordersStatPerService?.counts;
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-black-800 text-lg font-bold dark:text-white">
            Services
          </h4>
        </div>

        <div className="mb-6 flex items-center justify-center">
          <select className="dark:!bg-black-800 mb-3 mr-2 flex items-center justify-center rounded-[4px] py-1 px-2 text-sm font-bold text-gray-600 hover:cursor-pointer dark:text-white">
            <option value="Mensuel">Mensuel</option>
            <option value="Annuel">Annuel</option>
            <option value="weekly">Hebdomadaire</option>
          </select>
        </div>
      </div>

      <div className="mb-auto mt-5 flex h-[300px] w-full items-center justify-center">
        <PieChart options={pieChartOptions} series={pieChartData} />
      </div>
    </Card>
  );
};

export default PieChartCard;

import { useMutation, useQuery } from "@apollo/client";
import { GIFTS, COUNT_GIFTS, CHANGE_GIFT_STATUS } from "graphQl/tags";

export const useQueryGifts = (
  skip,
  limit,
  value = null,
  professional = null
) => {
  const { data, loading, refetch } = useQuery(GIFTS, {
    variables: {
      skip,
      limit,
      value,
      professional,
    },
    fetchPolicy: "no-cache",
  });
  return {
    gifts: data?.gifts,
    loadingGifts: loading,
    refetchGifts: refetch,
  };
};
export const useQueryCountGifts = (value = null, professional = null) => {
  const { data, loading, refetch } = useQuery(COUNT_GIFTS, {
    variables: {
      value,
      professional,
    },
    fetchPolicy: "no-cache",
  });
  return {
    countGifts: data?.countGifts,
    loading,
    refetch,
  };
};

export const useMutationGift = () => {
  const [changeGiftStatus] = useMutation(CHANGE_GIFT_STATUS);

  function changeGiftStatusHandler({ status, id }) {
    return changeGiftStatus({
      variables: {
        id,
        status,
      },
    });
  }
  return {
    changeGiftStatusHandler,
  };
};

// Custom components
import React from "react";

function SelectField(props) {
  const {
    label,
    id,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    onChange = () => {},
    extraLabel = "",
    value,
    defaultValue,
    children,
  } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`${extraLabel} text-black-800 text-sm dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        name={id}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      >
        {children}
      </select>
    </div>
  );
}

export default SelectField;

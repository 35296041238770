import { useState, useEffect, Fragment } from "react";
import ComplexTable from "./components/ComplexTable";
import {
  useMutationOrder,
  useQueryOrders,
  useQueryCountOrders,
} from "graphQl/hooks";
import Modal from "./components/Modal";
import Pagination from "components/pagination/Pagination";
import { useAdminContext } from "layouts/admin";
import { toast } from "react-toastify";

export const columnsDataComplex = [
  {
    Header: "NUMÉRO",
    accessor: "code",
  },
  {
    Header: "CRÉÉE PAR",
    accessor: "user",
  },
  {
    Header: "HAPPYEUR",
    accessor: "professional",
  },
  {
    Header: "POUR LE",
    accessor: "orderDate",
  },
  {
    Header: "STATUT",
    accessor: "status",
  },
];

const Orders = ({ professional, user }) => {
  const { search } = useAdminContext();
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(professional || user ? 999999 : 10);

  const clear = () => {
    setOrder(null);
  };
  const handleOpen = () => {
    if (open) clear();
    setOpen(!open);
    if (order) clear();
  };
  const setOrderHandle = ({ original }) => {
    setOrder(original);
    handleOpen();
  };
  const { changeOrderStatusHandler } = useMutationOrder();

  const { orders, loadingOrders, refetchOrders } = useQueryOrders(
    skip,
    limit,
    search,
    null,
    professional,
    user
  );
  const { countOrders, loading, refetch } = useQueryCountOrders(
    search,
    null,
    professional,
    user
  );
  const changeStats = (status) => {
    changeOrderStatusHandler({
      id: order?.id,
      status,
    }).then((httpResponse) => {
      if (!httpResponse?.errors) {
        toast.success("Statut du mission a été modifié avec succès");
        refetch();
        refetchOrders();
      }
    });
    handleOpen();
  };
  useEffect(() => {}, [skip, limit, countOrders, orders]);
  return (
    <div>
      {orders && !loadingOrders && (
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <ComplexTable
            setOrderHandle={setOrderHandle}
            columnsData={columnsDataComplex}
            tableData={orders}
          />
        </div>
      )}
      <Modal
        showModal={open}
        toogleModal={handleOpen}
        handleSubmit={changeStats}
        order={order}
      ></Modal>

      {!professional && !user && (
        <Pagination
          skip={skip}
          limit={limit}
          setSkip={setSkip}
          length={countOrders}
          setLimit={setLimit}
        />
      )}
    </div>
  );
};

export default Orders;

import Card from "components/card";
import ImageZoom from "components/imageZoom";
import React from "react";
import { userTypes } from "variables/constants";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");

const General = ({ user }) => {
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <p className="text-black-800 mt-2 px-2 text-base">
          {user?.profile?.bio}
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-md text-black-800">Téléphone</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {user?.mobile}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-md text-black-800">Adresse</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {user?.profile?.address}
          </p>
        </div>
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-md text-black-800">Date de naissance</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {moment(user?.profile?.birthday).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
          <p className="text-md text-black-800">Type</p>
          <p className="text-sm text-[#4263c9] dark:text-white">
            {userTypes[user?.type]}
          </p>
        </div>
        {user?.profile?.photoUrl && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
            <p className="text-md text-black-800">Photo de profil</p>
            <p className="mt-2 text-sm text-[#4263c9] dark:text-white">
              <ImageZoom
                url={user?.profile?.photoUrl}
                title="Photo de profil"
              />{" "}
            </p>
          </div>
        )}
        {user?.profile?.justification && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none">
            <p className="text-md text-black-800">Pièce d'identité</p>
            <p className="mt-2 text-sm text-[#4263c9] dark:text-white">
              <ImageZoom
                url={user?.profile?.justification}
                title="Pièce d'identité"
              />
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default General;
